<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-model="projectsFilter"
      :items="filtersState[$config.analyticsConfig.filter_slugs.projects].data"
      :loading="filtersState[$config.analyticsConfig.filter_slugs.projects].loading"
      :disabled="filtersState[$config.analyticsConfig.filter_slugs.projects].loading"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      :multiple="false"
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-projects-bottom"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.projects)"
      @update:search-input="highlightSelection($event, filtersState[$config.analyticsConfig.filter_slugs.projects].data, $config.analyticsConfig.filter_slugs.projects)"
      placeholder="Select Project"
      class="filter-select filter-select-projects-bottom mr-3"
    >
      <template v-slot:selection="{item}">
        <div class="selected-result d-flex align-center text-captions-1">
          {{item?.title}}
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex text-captions-1 fill-width">
                <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.analyticsConfig.filter_slugs.projects)"
          class="d-flex justify-center"
        >
          <v-progress-circular
            v-show="filtersState[$config.analyticsConfig.filter_slugs.projects].loading"
            :size="20"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="modulesFilter"
      :items="filtersState[$config.analyticsConfig.filter_slugs.bottom_modules].data"
      :loading="filtersState[$config.analyticsConfig.filter_slugs.bottom_modules].loading"
      :disabled="filtersState[$config.analyticsConfig.filter_slugs.bottom_modules].loading"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      :multiple="false"
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-modules-bottom"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.bottom_modules)"
      @update:search-input="highlightSelection($event, filtersState[$config.analyticsConfig.filter_slugs.bottom_modules].data, $config.analyticsConfig.filter_slugs.bottom_modules)"
      placeholder="Select Module"
      class="filter-select filter-select-modules-bottom mr-3"
    >
      <template v-slot:selection="{item}">
        <div class="selected-result d-flex align-center text-captions-1">
          {{item.title}}
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex text-captions-1 fill-width">
                <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.analyticsConfig.filter_slugs.bottom_modules)"
          class="d-flex justify-center"
        >
          <v-progress-circular
            v-show="filtersState[$config.analyticsConfig.filter_slugs.bottom_modules].loading"
            :size="20"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="dateFilter"
      :items="filtersState[$config.analyticsConfig.filter_slugs.bottom_date].data"
      :loading="filtersState[$config.analyticsConfig.filter_slugs.bottom_date].loading"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      :multiple="false"
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-date-bottom"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.bottom_date)"
      @update:search-input="highlightSelection($event, filtersState[$config.analyticsConfig.filter_slugs.bottom_date].data, $config.analyticsConfig.filter_slugs.bottom_date)"
      placeholder="Select Date"
      class="filter-select filter-select-date-bottom"
    >
      <template v-slot:selection="{item}">
        <div class="selected-result d-flex align-center text-captions-1">
          {{ item.title }}
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" @click="onClickDateItem">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex text-captions-1">
                <div v-html="item.htmlTitle || item.title"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.analyticsConfig.filter_slugs.bottom_date)"
          class="d-flex justify-center"
        >
          <v-progress-circular
            v-show="filtersState[$config.analyticsConfig.filter_slugs.bottom_date].loading"
            :size="20"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </v-autocomplete>

  </div>
</template>

<script>
import UiCheckbox from "@/components/UI/UiCheckbox.vue";
import {highlightText} from "@/utils/helpers";

export default {
  name: "BottomFilters" ,
  components: {
    UiCheckbox,
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    // IconCancel: () => import("@/components/icons/IconCancel")
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    filtersState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      debounce: null,
      debounceSearch: null,
    }
  },
  computed: {
    modulesFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.analyticsConfig.filter_slugs.bottom_modules]: val}})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.bottom_modules]
      }
    },
    dateFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.analyticsConfig.filter_slugs.bottom_date]: val}})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.bottom_date]
      }
    },
    projectsFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.analyticsConfig.filter_slugs.projects]: val, [this.$config.analyticsConfig.filter_slugs.bottom_modules]: []}})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.projects] ? Number(this.filter[this.$config.analyticsConfig.filter_slugs.projects]) : undefined
      }
    },
  },
  methods: {
    updateFilter(type) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        // if(this.$route.query.page && this.$route.query.page !== '1') {
        //   await this.$router.replace({query: {...this.$route.query, page: '1'}})
        // }
        this.$emit('updateFilter', type)
      }, 800)
    },
    endIntersect(entries, observer, isIntersecting, type) {
      if (isIntersecting && !this.filtersState[type].loading && this.filtersState[type].meta?.total > this.filtersState[type].data.length && !this.filtersState[type].search) {
        this.filtersState[type].page += 1
        this.$emit('loadMoreFilters', {type})
      }
    },
    highlightSelection($event, list, type) {
      this.filtersState[type].search = $event

      list.forEach(item => {
        item.htmlTitle = highlightText($event || '', item.title || '');
      });

      if($event) {
        clearTimeout(this.debounceSearch)
        this.debounceSearch = setTimeout(() => {
          this.$emit('loadMoreFilters', {type})
        }, 600)
      }
    },
    onClickDateItem() {
      if(this.dateFilter || this.dateFilter === 0) {
        this.dateFilter = undefined
        this.updateFilter(this.$config.analyticsConfig.filter_slugs.bottom_date)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.selected-result {
  padding: 3px 0;
}
.filter-select {
  width: 198px;
  max-width: 198px;
  :deep(.v-select__selections) {
    padding: 2px 0 !important;
    .selected-result {
      &:not(:first-child) {
        display: none !important;
      }
    }
    input {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.filter-select-modules-bottom, .filter-select-projects-bottom {
  :deep(.v-list-item--active) {
    pointer-events: none;
  }
}
</style>
