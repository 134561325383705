<template>
  <AnalyticsSectionWrapper class="px-6 py-6">
    <div class="mb-6 d-flex align-center relative">
      <BottomFilters
        class="mr-3"
        :filter="queryParams"
        :filtersState="filtersState"
        @updateFilter="updateFilter"
        @loadMoreFilters="loadFilters"
      >
      </BottomFilters>
      <div
        v-if="this.queryParams[this.$config.analyticsConfig.filter_slugs.bottom_date] && comparedText"
        class="text-captions-2 gray-60--text"
      >
        Compared with the past {{ comparedText }} <span v-if="comparedDates">({{comparedDates }})</span>
      </div>
      <v-progress-linear
        v-if="loadingData && sectionResponse"
        indeterminate
        color="accent"
        class="absolute progress-line"
      />
    </div>
    <div v-if="loadingData && !sectionResponse" class="loading d-flex justify-center align-center">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </div>
    <div v-else>
      <h2 class="text-body font-weight-semi-bold">First Asset Upload Timeframe</h2>
      <FirstAssetUploadTimeframeChart
        class="mb-6"
        :chartData="firstAssetUploadTimeframeData"
      />
      <h2 class="text-body font-weight-semi-bold">Task Completion Rate</h2>
      <div>
        <FirstAssetUploadTimeframeChart
          class="mb-6"
          :chartData="taskCompletionRateData"
        />
      </div>
    </div>
  </AnalyticsSectionWrapper>
</template>

<script>
import AnalyticsSectionWrapper from "@/views/analytics/components/AnalyticsSectionWrapper.vue";
import BottomFilters from "@/views/analytics/components/bottom/BottomFilters.vue";
import {formatTimeToHours, highlightText, isEqual, toFixedFirstSignificant} from "@/utils/helpers";
import FirstAssetUploadTimeframeChart from "@/views/analytics/components/bottom/FirstAssetUploadTimeframeChart.vue";
import {differenceInDays, format} from "date-fns";

export default {
  name: "BottomMetrics" ,
  components: {
    FirstAssetUploadTimeframeChart,
    BottomFilters,
    AnalyticsSectionWrapper
  },
  data() {
    return {
      filtersState: {
        bottom_modules: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        projects: {
          loading: false,
          search: '',
          page: 1,
          data: [],
          meta: null,
        },
        bottom_date: {
          loading: false,
          search: '',
          data: [
            {title: 'Last 7 days', id: '7'},
            {title: 'Last 30 days', id: '30'},
            {title: 'Last 3 Months', id: '90'},
            {title: 'Last 6 Months', id: '180'},
            {title: 'Last Year', id: '360'},
            {title: 'Year to date', id: 'year_to_date'},
          ],
        },
      },
      FILTER_ITEMS_COUNT: 15,
      loadingData: true,
      debounce: null,
      sectionResponse: null,
    }
  },
  computed: {
    queryParams() {
      return {
        bottom_date: this.$route.query.bottom_date || undefined,
        bottom_modules: this.$route.query.bottom_modules?.length ? this.$route.query.bottom_modules : undefined,
        projects: this.$route.query.projects?.length ? this.$route.query.projects : undefined,
        date: this.$route.query.date || undefined,
        spaces: Array.isArray(this.$route.query.spaces) ? this.$route.query.spaces.map(el => Number(el)) : this.$route.query.spaces ? [Number(this.$route.query.spaces)] : [],
      }
    },
    comparedText() {
      if(!this.sectionResponse?.filters) {
        return ''
      }
      const pastDays = differenceInDays(new Date(this.sectionResponse?.filters?.to), new Date(this.sectionResponse?.filters?.from)) || ''
      return `${pastDays} days`
    },
    comparedDates() {
      if(!this.sectionResponse?.filters) {
        return null
      }
      return `${format(new Date(this.sectionResponse.filters.from).setHours(0,0,0,0), 'MMM dd, yyyy')} - ${format(new Date(this.sectionResponse.filters.to).setHours(0,0,0,0), 'MMM dd, yyyy')}`
    },
    firstAssetUploadTimeframeData() {
      let labelSufix = ' Hours'
      let valueResultType = 'hours'
      const series = [{data: this.sectionResponse?.data?.['asset-timeframe']?.[0] ? [
          toFixedFirstSignificant(formatTimeToHours(this.sectionResponse?.data?.['asset-timeframe']?.[0]?.your_selection || 0)),
          toFixedFirstSignificant(formatTimeToHours(this.sectionResponse?.data?.['asset-timeframe']?.[0]?.space_average || 0))
        ] : []}]
      if(series[0]?.data?.some(el => el > 24)) {
        labelSufix = ' Days'
        valueResultType = 'days'
        series[0].data = series[0].data.map(el => el / 24)
      }
      return {
        series: series,
        categories: ['Your Selection', 'Space Average'],
        colors: ['#A572F9', '#C7DEFE'],
        labelSufix: labelSufix,
        valueResultType,
        height: '136',
        barHeight: '78%',
        tooltipTemplate: (valueInHours) => {
          const remainderFromDivision = valueInHours % 24
          const isMoreThenDay = valueInHours >= 24

          if(!isMoreThenDay) {
            return `<b>${toFixedFirstSignificant(valueInHours)}</b> hours`
          } else if(!remainderFromDivision) {
            const days = valueInHours / 24
            return `<b>${days}</b> ${days > 1 ? 'days' : 'day'}`
          } else {
            const days = Math.floor(valueInHours / 24)
            return `<b>${days}</b> ${days > 1 ? 'days' : 'day'} <b>${toFixedFirstSignificant(remainderFromDivision)}</b> hours`
          }
        },
        dataLabelsTemplate: (valueInHours) => {
          const remainderFromDivision = valueInHours % 24
          const isMoreThenDay = valueInHours >= 24
          if(!isMoreThenDay) {
            return `${toFixedFirstSignificant(valueInHours)} Hours`
          } else if(!remainderFromDivision) {
            const days = valueInHours / 24
            return `${days} ${days > 1 ? 'Days' : 'Day'}`
          } else {
            const days = Math.floor(valueInHours / 24)
            return `${days} ${days > 1 ? 'Days' : 'Day'} ${toFixedFirstSignificant(remainderFromDivision)} Hours`
          }
        },
      }
    },
    taskCompletionRateData() {
      return {
        series: [{data: this.sectionResponse?.data?.['task-completion']?.[0] ? [
            Number(this.sectionResponse?.data?.['task-completion']?.[0]?.task_completion_rate || 0),
          ] : []}],
        categories: ['Your Selection'],
        colors: ['#A572F9'],
        labelSufix: '%',
        height: '75',
        barHeight: '120%'
      }
    }
  },
  watch: {
    'queryParams.date': {
      async handler(val) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          if (val && val !== this.queryParams.bottom_date) {
            await this.$router.push({
              query: {
                ...this.$route.query,
                bottom_date: val
              }
            })
            await this.getInfo()
          }
        }, 600)
      }
    },
    'queryParams.spaces': {
      async handler(val, oldVal) {
        if (!isEqual(val, oldVal)) {
          clearTimeout(this.debounce)
          this.debounce = setTimeout(async () => {
            this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].page = 1

            await this.$router.push({
              query: {
                ...this.$route.query,
                bottom_modules: undefined,
                projects: undefined,
              }
            })
            await this.getProjects()
            await this.setDefaultFilters() // set default project filter

            await this.getModules()
            await this.setDefaultFilters() // set default project module
            await this.getInfo()
          }, 600)
        }
      }
    },
  },
  async mounted() {
    await this.getProjects()
    await this.setDefaultFilters() // set default project filter

    await this.getModules()
    await this.setDefaultFilters() // set default project module

    await this.getFilterItemsByIds()
    await this.getInfo(false)
  },
  methods: {
    async updateFilter(type) {
      if(type === this.$config.analyticsConfig.filter_slugs.spaces) {
        this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].page = 1
        await this.getProjects()
        await this.setDefaultFilters()
      } else if(type === this.$config.analyticsConfig.filter_slugs.projects) {
        this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].page = 1
        if(!this.queryParams.projects) {
          await this.setDefaultFilters()
        }
        await this.getModules()
        await this.setDefaultFilters()
      } else if(type === this.$config.analyticsConfig.filter_slugs.bottom_modules) {
        if(!this.queryParams.bottom_modules) {
          await this.setDefaultFilters()
        }
      }
      await this.getInfo(false)
    },
    loadFilters(data) {
      if(data.type === this.$config.analyticsConfig.filter_slugs.bottom_modules) {
        this.getModules(true)
      } else if(data.type === this.$config.analyticsConfig.filter_slugs.projects) {
        this.getProjects(true)
      }
    },
    async getModules(merge, titles) {
      this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].page,
        }
        if(this.queryParams.projects) {
          params.project_ids = [this.queryParams.projects]
        } else {
          this.filterFetchDataSetter({merge, data: [], type: this.$config.analyticsConfig.filter_slugs.bottom_modules, meta: null, ids: []})
          return
        }
        if(titles) {
          params.titles = titles
        }
        if(this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules]?.search
          params.page = 1
        }
        const res = await this.$api.dashboard.getFilterModules(params)

        const mapedData = [...new Set(res.data.map(module => module.title))].map(title => ({ // filter duplicates
          title: title,
          id: title
        })).filter(module => merge ? !this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].data.map(el => el.id).includes(module.id) : true )
        this.filterFetchDataSetter({merge, data: mapedData, type: this.$config.analyticsConfig.filter_slugs.bottom_modules, meta: res.meta, ids: titles})

      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].loading = false
      }
    },
    async getProjects(merge, ids) {
      this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].loading = true
      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          page: this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].page,
        }

        if(ids?.length) {
          params.ids = ids
        }
        if(this.filtersState[this.$config.analyticsConfig.filter_slugs.projects]?.search?.length >= 2) {
          params.search = this.filtersState[this.$config.analyticsConfig.filter_slugs.projects]?.search
          params.page = 1
        }

        if(this.queryParams.spaces.length) {
          params.space_ids = this.queryParams.spaces
        }

        const res = await this.$api.dashboard.getFilterProjects(params)

        this.filterFetchDataSetter({merge, data: res.data, type: this.$config.analyticsConfig.filter_slugs.projects, meta: res.meta, ids})
      } catch (error) {
        console.error(error)
      } finally {
        this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].loading = false
      }
    },
    async getInfo() {
      try {
        this.loadingData = true

        if(!this.queryParams.bottom_modules || !this.queryParams.projects) {
          this.sectionResponse = null
          return
        }
        const params = {
          spaces: this.queryParams.spaces,
          module: this.queryParams.bottom_modules,
          date: this.queryParams.bottom_date,
          project: this.queryParams.projects,
        }
        this.sectionResponse = await this.$api.analytics.getAssetTimeframeAndTaskCompletion(params)

      } catch (error) {
        console.error(error);
      } finally {
        this.loadingData = false
      }
    },
    async setDefaultFilters() {
      const defaultQuery = {}
      if(!this.queryParams.bottom_date) {
        defaultQuery.bottom_date = this.queryParams.date || '30'
      }

      if(!this.queryParams.projects && this.filtersState[this.$config.analyticsConfig.filter_slugs.projects]?.data?.[0]?.id) {
        defaultQuery.projects = this.filtersState[this.$config.analyticsConfig.filter_slugs.projects]?.data?.[0]?.id
      }
      if(!this.queryParams.bottom_modules && this.queryParams.projects && this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules]?.data?.[0]?.id) {
        defaultQuery.bottom_modules = this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules]?.data?.[0]?.id
      }

      if(Object.keys(defaultQuery).length) {
        await this.$router.push({
          query: {
            ...this.$route.query,
            ...defaultQuery
          }
        })
      }
    },
    filterFetchDataSetter({merge, data, type, meta, ids}) {
      if(merge) {
        const itemsToMerge = data.filter(el => !this.filtersState[type].data.find(item => item.id === el.id))
        this.filtersState[type].data = [...this.filtersState[type].data, ...itemsToMerge];
      } else {
        this.filtersState[type].data = data
      }

      if(!ids && !this.filtersState[type].search) {
        this.filtersState[type].meta = meta
      }

      if(this.filtersState[type].search) {
        this.filtersState[type].data.forEach(item => {
          item.htmlTitle = highlightText(this.filtersState[type].search || '', item.title || '');
        });
      }
    },
    async getFilterItemsByIds() {
      let projectsMissedId = null
      let modulesMissedTitle = null

      if(this.queryParams[this.$config.analyticsConfig.filter_slugs.projects]) {
        const isMissed = !this.filtersState[this.$config.analyticsConfig.filter_slugs.projects].data.find(project => project.id === Number(this.queryParams[this.$config.analyticsConfig.filter_slugs.projects]))
        projectsMissedId = isMissed ? Number(this.queryParams[this.$config.analyticsConfig.filter_slugs.projects]) : null
      }

      if(this.queryParams[this.$config.analyticsConfig.filter_slugs.bottom_modules]) {
        const isMissed = !this.filtersState[this.$config.analyticsConfig.filter_slugs.bottom_modules].data.find(project => project.id === Number(this.queryParams[this.$config.analyticsConfig.filter_slugs.bottom_modules]))
        modulesMissedTitle = isMissed ? Number(this.queryParams[this.$config.analyticsConfig.filter_slugs.bottom_modules]) : null
      }

      const promises = []

      if(projectsMissedId) {
        promises.push(this.getProjects(true, [projectsMissedId]))
      }
      if(modulesMissedTitle) {
        promises.push(this.getModules(true, [modulesMissedTitle]))
      }
      await Promise.all(promises)
    },
  }
}
</script>


<style scoped lang="scss">
.progress-line {
  bottom: -4px;
}
.loading {
  min-height: 268px;
}
</style>
