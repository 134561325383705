<template>
  <div>
    <apexchart
      ref="metrics-chart"
      type="bar"
      :options="chartOptions"
      :series="series"
      height="270"
      class="my-apex"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {isEqual} from "@/utils/helpers";
export default {
  name: "MetricsChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: false,
              pan: false,
              reset: false,
              customIcons: []
            },
          },
          type: "bar",
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          crosshairs: {
            show: true,
            width: 'tickWidth'
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
        },
        colors: [
          '#C7DEFE',
        ],
        fill: {
          opacity: 1,
        },
        grid: {
          show: true,
          borderColor: "#DCDDDE40",
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: 'left',
          fontSize: '10px',
          markers: {
            size: 4,
            shape: 'circle'
          },
          itemMargin: {
            horizontal: 8,
          },
          onItemClick: {
            toggleDataSeries: false
          },
        },
        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No data'
        }
      },
      oldChartData: null,
    }
  },
  watch: {
    chartData: {
      async handler(val) {
        if(isEqual(val, this.oldChartData)) {
          return
        }
        this.oldChartData = JSON.parse(JSON.stringify(val))

        const chartData = this.chartData

        this.series = []
        this.series = [...val.series]
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: [...val.categories],
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return value + (val.yAxisSuffix ? ` ${val.yAxisSuffix}` : '');
              },
            },
          },
          tooltip: {
            enabled: !this.series?.[0]?.data?.every(el => !el),
            shared: true,
            intersect: false,
            followCursor: true,
            custom: function ({ series, dataPointIndex }) {
              let value = val.valueResultType === 'days' ? series?.[0]?.[dataPointIndex] * 24 : series?.[0]?.[dataPointIndex]
              const result =  chartData.tooltipTemplate(value)
              return `<div class="chart-tooltip-box">
                      <span>${result}</span>
                    </div>`;
            },
          },
        }

        if(this.showModuleAverage) {
          this.toggleModuleAverage(true)
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped lang="scss">
.chart-tooltip-box {
  background: white;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #000;
  font-size: 10px;
  font-family: "Inter", sans-serif;
  color: #000;
}
</style>
