<template>
  <div class="d-flex align-center">
    <v-autocomplete
      v-model="spacesFilter"
      :items="getSpaces"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      multiple
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-space"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.spaces)"
      @update:search-input="highlightSelection($event, getSpaces, $config.analyticsConfig.filter_slugs.spaces)"
      placeholder="All Spaces"
      class="filter-select filter-select-space mr-2"
    >
      <template #prepend-item>
        <div class="px-4">
          <v-chip
            v-for="chip in selectedSpaces"
            class="filter-chip ml-0 mr-1 px-1"
            :key="chip.id"
          >
            <template #default>
              <div class="d-flex align-center max-width-100">
                <div class="text-captions-2 accent-100--text text-truncate">{{chip.title}}</div>
                <v-btn
                  class="ml-1"
                  height="12"
                  icon
                  width="12"
                  @click="removeFilter('spacesFilter', $config.analyticsConfig.filter_slugs.spaces, chip.id)"
                >
                  <IconCancel width="10" height="10" class="accent-100--text" />
                </v-btn>
              </div>
            </template>
          </v-chip>
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-icon class="d-flex align-center mt-0 mb-0 list-item-icon">
              <v-sheet rounded="circle" :color="item.color" width="14" height="14"></v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex text-captions-1 fill-width">
                <div v-html="item.htmlTitle || item.title" class="fill-width text-truncate"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template v-slot:selection="{}">
        <div class="selected-result d-flex align-center text-captions-1">
          Spaces <div class="selected-count d-flex align-center justify-center ml-1 gray-60--text">{{spacesFilter.length}}</div>
        </div>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="dateFilter"
      :items="filtersState[$config.analyticsConfig.filter_slugs.date].data"
      :loading="filtersState[$config.analyticsConfig.filter_slugs.date].loading"
      :item-text="'title'"
      item-value="id"
      :return-object="false"
      :multiple="false"
      persistent-hint
      hide-details
      :hide-selected="false"
      dense
      outlined
      append-icon=""
      attach=".filter-select-date"
      :menu-props="{contentClass: 'filter-autocomplete-menu'}"
      @change="updateFilter($config.analyticsConfig.filter_slugs.date)"
      @update:search-input="highlightSelection($event, filtersState[$config.analyticsConfig.filter_slugs.date].data, $config.analyticsConfig.filter_slugs.date)"
      placeholder="Select Date"
      class="filter-select filter-select-date"
    >
      <template v-slot:selection="{item}">
        <div class="selected-result d-flex align-center text-captions-1">
          {{ item.title }}
<!--          Date <div class="selected-count d-flex align-center justify-center ml-1 gray-60&#45;&#45;text">1</div>-->
        </div>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" @click="onClickDateItem">
          <template #default="{active}">
            <v-list-item-action class="mt-2 mb-2">
              <UiCheckbox
                checkbox-form="square"
                :input-value="active"
              />
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex text-captions-1">
                <div v-html="item.htmlTitle || item.title"></div>
              </div>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
      <template #append>
        <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <div
          v-intersect="(entries, observer, isIntersecting) => endIntersect(entries, observer, isIntersecting, $config.analyticsConfig.filter_slugs.date)"
          class="d-flex justify-center"
        >
          <v-progress-circular
            v-show="filtersState[$config.analyticsConfig.filter_slugs.date].loading"
            :size="20"
            color="accent"
            indeterminate
          />
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import UiCheckbox from "@/components/UI/UiCheckbox.vue";
import {mapGetters} from "vuex";
import {highlightText, isEqual} from "@/utils/helpers";

export default {
  name: "GlobalFilters" ,
  components: {
    UiCheckbox,
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancel: () => import("@/components/icons/IconCancel")
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    filtersState: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      debounce: null,
      debounceSearch: null,
    }
  },
  computed: {
    ...mapGetters([
      'getSpaces',
    ]),
    spacesFilter: {
      set(val) {
        const newQuery = {
          ...this.$route.query,
          [this.$config.analyticsConfig.filter_slugs.spaces]: val.map(el => el.toString())
        }
        if(isEqual(this.$route.query, newQuery)) {
          return
        }
        this.$router.replace({query: newQuery})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.spaces]
      }
    },
    dateFilter: {
      set(val) {
        this.$router.replace({query: {...this.$route.query, [this.$config.analyticsConfig.filter_slugs.date]: val}})
      },
      get() {
        return this.filter[this.$config.analyticsConfig.filter_slugs.date]
      }
    },
    selectedSpaces() {
      return this.getSpaces.filter(el => this.spacesFilter.includes(el.id))
    },
  },
  methods: {
    updateFilter(type) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        this.$emit('updateFilter', type)
      }, 800)
    },
    endIntersect(entries, observer, isIntersecting, type) {
      if (isIntersecting && !this.filtersState[type].loading && this.filtersState[type].meta?.total > this.filtersState[type].data.length && !this.filtersState[type].search) {
        this.filtersState[type].page += 1
        this.$emit('loadMoreFilters', {type})
      }
    },
    highlightSelection($event, list, type) {
      this.filtersState[type].search = $event

      list.forEach(item => {
        item.htmlTitle = highlightText($event || '', item.title || '');
      });

      if($event) {
        clearTimeout(this.debounceSearch)
        this.debounceSearch = setTimeout(() => {
          this.$emit('loadMoreFilters', {type})
        }, 600)
      }
    },
    onClickDateItem() {
      if(this.dateFilter || this.dateFilter === 0) {
        this.dateFilter = undefined
        this.updateFilter(this.$config.analyticsConfig.filter_slugs.date)
      }
    },
    removeFilter(name, type, id) {
      this[name] = this[name].filter(item => item !== id)
      this.updateFilter(type)
    }
  }
}
</script>

<style scoped lang="scss">
.selected-count {
  width: 20px;
  height: 20px;
  background-color: var(--v-gray-30-base);
  border-radius: 50%;
}
.selected-result {
  padding: 3px 0;
}
.filter-select {
  width: 227px;
  max-width: 227px;
  :deep(.v-select__selections) {
    padding: 2px 0 !important;
    .selected-result {
      &:not(:first-child) {
        display: none !important;
      }
    }
    input {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.list-item-icon {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100%;
}
</style>
