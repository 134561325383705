<template>
  <v-list
    class="pmm-list space-y-3 py-0"
    flat
    expand
  >
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      no-action
      append-icon=""
      color="gray-60"
      class="pmm-group"
    >
      <template v-slot:activator>
        <v-list-item-content class="py-4">
          <v-list-item-title class="d-flex align-center">
            <div class="icon-wrapper d-flex align-center justify-center rounded-circle gray-10 mr-2">
              <component :is="item.icon" width="15" class="gray-60--text" />
            </div>

            <div>
              <div class="gray-60--text text-captions-2">{{item.title}}</div>
              <div class="d-flex align-end">
                <div v-if="item?.series?.[0]?.data?.length || item.showDataAlways" class="gray-100--text font-weight-semi-bold text-body-md mr-1">{{item.measurementLabel(item[item.valueToShowSlug])}}</div>
                <div
                  class="rate-changes text-captions-2 font-weight-bold"
                  :class="[
                    arrowColor(item) === arrowColors.green ? 'success-100--text' : arrowColor(item) === arrowColors.none ? 'gray-100--text' : 'error-70--text'
                  ]"
                >
                  <template v-if="(item?.series?.[0]?.data?.length || item.showDataAlways) && item.growthRate === 0"></template>
                  <template v-else>{{arrowDirection(item) === arrowDirections.up ? '↑ ' : arrowDirection(item) === arrowDirections.none ? '-' : '↓ ' }}</template>
                  {{ item.growthRate || (item.growthRate === 0 && item.showDataAlways) ? item.growthRate + '%' : '' }}
                </div>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item class="px-0">
        <v-list-item-content class="pt-4 pb-8">
          <div class="item-content-title font-weight-semi-bold text-body gray-800--text pb-3 px-6 mb-5">
            {{ item.title }}
          </div>
          <div class="px-6">
            <MetricsChart :chartData="item" />
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>

import MetricsChart from "@/views/analytics/components/metrics/MetricsChart.vue";

export default {
  name: "ProjectManagementMetrics",
  components: {
    MetricsChart,
    IconNewFile: () => import("@/components/icons/IconNewFile"),
    IconCheck: () => import("@/components/icons/IconCheck"),
    IconClockFilled: () => import("@/components/icons/IconClockFilled"),
    IconCycle: () => import("@/components/icons/IconCycle")
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      arrowDirections: {
        up: 'up',
        down: 'down',
        none: 'none'
      },
      arrowColors: {
        green: 'green',
        red: 'red',
        none: 'none'
      }
    }
  },
  methods: {
    arrowDirection(item) {
      if (item.growthRate > 0) {
        return this.arrowDirections.up;
      } else if (item.growthRate < 0) {
        return this.arrowDirections.down;
      } else {
        return this.arrowDirections.none;
      }
    },
    arrowColor(item) {
      if (item.growthRate > 0 && !item.growsOpposite) {
        return this.arrowColors.green;
      } else if (item.growthRate < 0 || (item.growthRate > 0 && item.growsOpposite)) {
        return this.arrowColors.red;
      } else {
        return this.arrowColors.none;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pmm-group {
  border-radius: 5px;
  border: 1px solid var(--v-gray-30-base);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  :deep(.v-list-group__header) {
    border-radius: 5px;
    padding: 0 24px;
    background: var(--v-neturals-10-base);
    .v-ripple__container {
      display: none;
    }
  }
}
.pmm-list {
  :deep(.v-list-group--active) {
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  }
  :deep(.v-list-group) {
    .v-list-group__items {
      border-top: 1px solid var(--v-gray-25-base);
    }
  }
}
.icon-wrapper {
  width: 35px;
  height: 35px;
}
.item-content-title {
  border-bottom: 1px solid var(--v-gray-25-base);
}
.rate-changes {
  line-height: 16px;
}
</style>
